import React, { Component } from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import SubmittedOrderTable from "components/Order/SubmittedOrderTable"

import { fetchUserDataByUid, retrieveOrderByOrderNumber } from "api/db"

class SubmittedOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderNumber: 0,
      order: {},
      user: {},
      orderExists: false,
      totalCases: 0,
      totalPrice: 0,
      dateSubmitted: "",
    }
  }

  componentDidMount() {
    let order = window.location.pathname.slice(8)
    this.setState({ orderNumber: order })
    retrieveOrderByOrderNumber(order).then(snapshot => {
      let order = snapshot.val()
      let orderNumber = order.orderNumber
      let uid = order.uid
      let totalCases = order.totalCases
      let totalPrice = order.totalPrice
      let dateSubmitted = order.dateUpdated
      let notes
      if (order.notes) {
        notes = order.notes
        delete order.notes
      } else {
        notes = ""
      }
      this.getUserData(uid)
      delete order.dateUpdated
      delete order.status
      delete order.orderNumber
      delete order.uid
      delete order.totalCases
      delete order.totalPrice
      this.setState({
        order: order,
        orderNumber: orderNumber,
        totalCases: totalCases,
        totalPrice: totalPrice,
        dateSubmitted: dateSubmitted,
        notes: notes
      })
    })
  }

  getUserData = uid => {
    fetchUserDataByUid(uid).on(
      "value",
      function(snapshot) {
        let data = snapshot.val()
        this.setState({
          user: data,
          orderExists: true,
        })
      }.bind(this)
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Orders | Tick Tock Inc." />
        <section className="ticktockinc-container-wide">
          {this.state.orderExists ? (
            <SubmittedOrderTable
              user={this.state.user}
              orderNumber={this.state.orderNumber}
              data={this.state.order}
              totalCases={this.state.totalCases}
              totalPrice={this.state.totalPrice}
              dateSubmitted={this.state.dateSubmitted}
              notes={this.state.notes}

            />
          ) : (
            <div className="container is-fullhd">
              <div className="notification is-size-4 has-text-weight-bold has-text-centered">
                Fetching order from our database...
              </div>
            </div>
          )}
        </section>
      </Layout>
    )
  }
}

export default SubmittedOrder
