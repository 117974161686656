import React, { useState } from "react"
import { Link } from "gatsby"

const OrderTable = ({
  user,
  orderNumber,
  data,
  totalPrice,
  totalCases,
  dateSubmitted,
  notes,
}) => {
  let items = Object.values(data)

  function getPath(itemCode) {
    if (itemCode) {
      return `/products/${itemCode.replace(/\s+/g, "-").toLowerCase()}`
    }
  }

  return (
    <section className="is-printer-friendly">
      <style>{`@media print {.is-printer-friendly {font-size: 10px !important;}}`}</style>
      <p className="is-size-3 has-text-weight-bold has-text-centered">
        Order #{orderNumber}
      </p>
      <p className="is-size-5 is-uppercase has-text-weight-bold has-text-right">
        {dateSubmitted}
      </p>
      <p className="is-size-5 is-uppercase"> Bill To: </p>
      <h1 className="is-size-3 has-text-weight-bold">{user.companyName}</h1>
      <p className="is-size-5"> {user.companyAddress}</p>
      <p className="is-size-5">
        {" "}
        {user.companyCity}, {user.companyState}{" "}
      </p>
      <p className="is-size-5">
        {" "}
        Phone:{" "}
        {user.companyPhoneNumber
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}{" "}
        | Fax:{" "}
        {user.companyFaxNumber
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}{" "}
        | Email: {user.emailAddress}
      </p>
      <br />{" "}
      {notes ? (
        <div>
          <p className="is-size-5">
            <b>Notes</b>: {notes}
          </p>{" "}
        </div>
      ) : null}
      <br />
      <table className="table is-bordered is-hoverable is-fullwidth ">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Description</th>
            <th>Packing</th>
            <th>Cases</th>
            <th>Pieces</th>
            <th>Unit Price</th>
            <th>Case Price</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            let price = (Math.round(item.price * 100) / 100).toFixed(2)

            return (
              <tr key={item.itemCode}>
                <th>
                  {" "}
                  <Link to={getPath(item.itemCode)}>{item.itemCode}</Link>
                </th>
                <th className="product-page-description">{item.description}</th>
                <th>{item.packing}/cs</th>
                <th>{item.cases}</th>
                <th>{item.pieces}</th>
                <th className="has-text-right">{price}</th>
                <th className="has-text-right">{item.totalPrice}</th>
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <p className="has-text-weight-bold has-text-right is-size-4">
        Total Cases: {totalCases}{" "}
      </p>
      <p className="has-text-weight-bold has-text-right is-size-4">
        Order Total: ${totalPrice ? totalPrice.toLocaleString() : totalPrice}
      </p>
      <br />
      <br />
    </section>
  )
}

export default OrderTable
